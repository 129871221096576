.adminLoginPage {
  min-height: 100vh;
  display: grid;
  place-items: center;

  .formLogin {
    input {
      width: 400px;
    }
  }
}
